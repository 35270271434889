<template>
  <div class="vue-form-generator">
    <b-card v-if="tagItem">
      <div slot="header">
        <strong>{{ $t('discountsGrid') }}</strong>
      </div>
      <b-row>
        <b-col v-for="(discount,i) in $v.form.discounts.$each.$iter" :key="i" sm="12" md="12" lg="6">
          <b-card>
            <div slot="header">
              <strong>{{ getRoleName(i) }}</strong>
            </div>
            <div v-for="(level,j) in discount.levels.$each.$iter" :key="j" style="vertical-align: middle; line-height: 32px; margin-bottom: 4px">
              <b-row>
                <b-col sm="2">
                  {{ $t('price') }}
                </b-col>
                <b-col sm="4" :class="{'error': level.level.$invalid && level.level.$dirty}">
                  <b-form-input v-model="level.level.$model" min="0" required type="number" style="width: 45%; display: inline-block"/> -
                  <b-form-input :value="getTopValue(i,j)" disabled style="width: 45%; display: inline-block"/>
                </b-col>
                <b-col sm="3">
                  {{ $t('discount') }}, %
                </b-col>
                <b-col sm="2" :class="{'error': level.value.$invalid && level.value.$dirty}">
                  <b-form-input v-model="level.value.$model" min="0" required type="number"/>
                </b-col>
                <b-col sm="1">
                  <a href="#" class="fa fa-remove" @click.prevent="removeLevel(i,j)" />
                </b-col>
              </b-row>
              <b-row v-if="(level.value.$invalid && level.value.$dirty) || (level.level.$invalid && level.level.$dirty)">
                <b-col sm="6" class="errors">
                  <div v-if="level.level.$invalid && level.level.$dirty">
                    <span v-if="!level.level.required">{{ $t('levelRequired') }}.</span>
                    <span v-if="!level.level.minValue">{{ $t('levelMinValue') }}.</span>
                  </div>
                </b-col>
                <b-col sm="6" class="errors">
                  <div v-if="level.value.$invalid && level.value.$dirty">
                    <span v-if="!level.value.required">{{ $t('discountRequired') }}.</span>
                    <span v-if="!level.value.minValue">{{ $t('discountMinValue') }}.</span>
                  </div>
                </b-col>
              </b-row>
            </div>
            <a href="#" @click.prevent="addLevel(i)">
              {{ $t('addDiscount') }}
            </a>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="2">
          <b-button
            type="submit"
            block
            variant="primary"
            :disabled="$v.$dirty && $v.$invalid"
            @click.prevent="submit"
          >Save</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {APIService} from "../../services/api";
  import i18n from "../../plugins/i18n";
  import {numeric, required} from "vuelidate/lib/validators";

  export default {
    name: 'TagItemDiscount',
    props: ['id'],
    data: () => {
      return {
        loaded: false,
        discountType: null,
        tagItem: null,
        form: {
          discounts: [
            {
              userRole: 1,
              discountTypeId: 2,
              status: 1,
              levels: []
            },
            {
              userRole: 2,
              discountTypeId: 2,
              status: 1,
              levels: []
            },
            {
              userRole: 3,
              discountTypeId: 2,
              status: 1,
              levels: []
            },
          ]
        }
      }
    },
    validations: {
      form: {
        discounts: {
          $each: {
            levels: {
              $each: {
                value: {
                  required,
                  numeric,
                  minValue: 0,
                },
                level: {
                  required,
                  numeric,
                  minValue: 0,
                },
              }
            }
          }
        }
      }
    },
    methods: {
      getRoleName(index) {
        const roleIndex = parseInt(index) + 1;
        if (roleIndex === 1) {
          return i18n.t('roleCustomer')
        }
        if (roleIndex === 2) {
          return i18n.t('roleFarmer')
        }
        return i18n.t('rolePromo')
      },
      getTopValue (discountIndex, levelIndex) {
        levelIndex++;
        if (
          this.form &&
          this.form.discounts &&
          this.form.discounts[discountIndex].levels[levelIndex] &&
          this.form.discounts[discountIndex].levels[levelIndex].level
        ) {
          return parseInt(this.form.discounts[discountIndex].levels[levelIndex].level) - 1;
        }
        return '-';
      },
      addLevel(discountIndex){
        this.form.discounts[discountIndex].levels.push({
          id: null,
          level: null,
          value: null,
          type: 2,
          status: 1
        })
      },
      removeLevel(discountIndex, levelIndex) {
        if (this.form && this.form.discounts) {
          this.form.discounts[discountIndex].levels.splice(levelIndex, 1);
        }
      },
      async submit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.tagItem.discounts = this.form.discounts;
          this.tagItem.discounts = this.tagItem.discounts.map(discount => {
            discount.discountType = {
              id: this.discountType.id
            }
            if (!discount.id) {
              delete discount.id;
            }
            discount.levels = discount.levels.map(level => {
              if (!level.id) {
                delete level.id;
              }
              return level;
            })
            return discount;
          });
          try {
            await APIService.patch(APIService.getRestFullEntityUrl('tag-item', this.tagItem), this.tagItem);
            this.$eventBus.$emit('alert', {
              type: 'success',
              text: i18n.t('dataWasSuccessfullySaved'),
            });
            this.$router.back();
          } catch (e) {
            console.error(e);
            this.$eventBus.$emit('alert', {
              type: 'danger',
              text: i18n.t('errorOnDataSaving'),
            });
          }
        }
      }
    },
    async beforeMount() {
      const discountTypes = await APIService.get(`discount-type?filter=title||$eq||Producer`);
      if (discountTypes.length > 0) {
        this.discountType = discountTypes[0];
        this.tagItem = await APIService.get(`tag-item/${this.id}?join=discounts&join=discounts.levels`);
        for (let i=1; i<=3; i++) {
          const discount = this.tagItem.discounts.find(discount => Number(discount.userRole) === i);
          if (discount) {
            this.form.discounts[i-1] = discount;
          }
        }
        console.log('this.form.discounts', this.form.discounts);
      }
    }
  }
</script>

<style lang="scss">
.vue-form-generator {
  .error {
    input {
      border-color: red;
    }
  }
  .errors {
    span {
      color: red;
    }
  }
}
</style>
